@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

* {
  box-sizing: border-box;
}


body {
  background-color: #222;
  background-size: cover;
  font-family: "Roboto", sans-serif;
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
}

.main {
  display: flex;
}


/* NAVBAR */
.navigationWrapper {
  position: absolute;
  top: 0;
  left: 0;
  max-height: 20vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: transparent;
  /* box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.25); */
  color: white;
  text-transform: uppercase;
  width: 100vw;
  z-index: 5000;
}

.logoWrapper {
  display: flex;
  cursor: pointer;
}

.stylish {
  font-weight: bolder;
  font-size: xx-large;
}

.logo {
  padding-left: 4px;
  font-size: xx-large;
  color: red;
}

.navigation {
  display: flex;
  list-style-type: none;
  margin-right: 2%;
}

.navigation li {
  opacity: 1;
  list-style-type: none;
  text-decoration: none;
  font-weight: bolder;
  transition: all 0.3s ease-in-out;
}

.parent {
  padding: 0 10px;
  cursor: pointer;
  color: black;
}

.parent2 {
  padding: 0 10px;
  cursor: pointer;
  color: white;
}

.parent:hover {
  color: red;
}

.parent2:hover {
  color: red;
}

.link {
  position: relative;
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  color: white;
}

.link-logo {
  position: relative;
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  color: white;
}

.link:hover {
  color: red
}

.fa-minus {
  opacity: 0;
  transition: all 0.3s ease-in-out;
  position: absolute;
  left: -16px;
  top: 3px;
}

.fa-plus {
  opacity: 1;
  transition: all 0.3s ease-in-out;
}

.fas {
  color: #914cea;
  margin: -2px 4px 0;
  font-size: 10px;
  transition: all 0.3s ease-in-out;
}

li a {
  font-size: 17px;
  padding: 0 5px;
}

/* MENU BUTTON */
#menuToggle {
  display: block;
  position: relative;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
}

#menuToggle a {
  text-decoration: none;
  color: #232323;

  transition: color 0.3s ease;
}

#menuToggle a:hover {
  color: tomato;
}


#menuToggle input {
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;

  cursor: pointer;

  opacity: 0;
  /* hide this */
  z-index: 2;
  /* and place it over the hamburger */

  -webkit-touch-callout: none;
}

/*
 * Just a quick hamburger
 */
#menuToggle span {
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;

  background: #222;
  border-radius: 3px;

  z-index: 1;

  transform-origin: 4px 0px;

  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
    opacity 0.55s ease;
}

#menuToggle span:first-child {
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2) {
  transform-origin: 0% 100%;
}

#menuToggle input:checked~span {
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
  background: #fff;
}

#menuToggle input:checked~span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

#menuToggle input:checked~span:nth-last-child(2) {
  transform: rotate(-45deg) translate(0, -1px);
}

#menu {
  position: absolute;
  /* top: 0px;
  left: 600px; */
  width: 400px;
  margin: -45px 0 0 -300px;
  padding: 50px;
  background: #222;
  list-style-type: none;
  border-radius: 12px;
  -webkit-font-smoothing: antialiased;
  /* to stop flickering of text in safari */

  transform-origin: 0% 0%;
  transform: translate(100%, 0);

  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0);
}

#menu li {
  padding: 10px 0;
  font-size: 16px;
}

#menu li:hover {
  color: red;
  cursor: pointer;
}

#menuToggle input:checked~ul {
  transform: none;
}

/* Card Deck */
.Deck {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -3%;
}

.Deck h3 {
  color: WhiteSmoke;
  font-size: 40px;
  text-transform: uppercase;
  letter-spacing: 5px;
}

.Deck h3 span {
  color: Red;
}

.Deck h4 {
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 5px;
  color: WhiteSmoke;
}

.Deck h4 span {
  color: Red;
}

.Cards {
  margin-top: 80px;
}

.Card {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  max-width: 400px;
}

.deal-button {
  margin: 0.5em;
  padding: 0.5em 1.5em;
  border: 2px solid Red;
  border-radius: 5px;
  outline: none;
  background: none;
  font-size: 20px;
  color: WhiteSmoke;
  transition: 0.25s;
}

.deal-button:hover {
  box-shadow: inset 0 0 0 2rem Red;
  cursor: pointer;
}



/* SLider */
.slider .navigations {
  position: absolute;
  min-width: 3vw;
  height: 100%;
  top: 45vh;
  left: 1vw;
  z-index: 1001;
}

.slider .navigations .dots {
  height: 100%;
  width: 100%;
  padding: 0px 0px;
  display: flex;
  flex-direction: column;
}

.slider .navigations .dots li {
  width: 10px;
  height: 10px;
  cursor: pointer;
  transition: 0.3s;
  background: #fff;
  border-radius: 50%;
  margin: 15%;
}

#dot1 {
  background: red;
}

#dot2 {
  background: white;
}

#dot2 {
  background: white;
}

.slider .navigations .dots li:hover {
  filter: blur(2px);
}

.slider .navigations .dots li.active:hover {
  filter: blur(0px);
}

/* Page Movenment */

#page1 {
  background-image: url('https://res.cloudinary.com/dqcmy8k1n/image/upload/v1661409424/Tarot%20Cards/Hunt_Trees_rjcnx1.jpg');
  background-size: cover;
  background-position: center;
  box-shadow: 0px 0px 90px 90px black;
  width: 100vw;
  height: 100vh;
  overflow: visible;
  transform: translateY(0%);
  transition: transform 0.5s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  filter: brightness(200%);
}

#page2 {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  transform: translateY(100%);
  transition: transform 0.5s ease;
  display: flex;
  justify-content: center;
  position: absolute;
  z-index: 1000;
  margin: 0 auto;
  margin-top: 80px;
  padding-top: 5%;
}

#page3 {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  transform: translateY(100%);
  transition: transform 0.5s ease;
  display: flex;
  justify-content: center;
  position: absolute;
  z-index: 1000;
}

/* Parallax */
#parallax {
  position: relative;
  width: 100%;
  height: 100vh;
  background-image: url(https://raw.githubusercontent.com/oscicen/oscicen.github.io/master/img/depth-3.png), url(https://raw.githubusercontent.com/oscicen/oscicen.github.io/master/img/depth-2.png), url(https://raw.githubusercontent.com/oscicen/oscicen.github.io/master/img/depth-1.png);
  background-repeat: no-repeat;
  background-position: center;
  background-position: 50% 50%;
}

h1 {
  position: absolute;
  top: 47%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-family: "Arial";
  text-transform: uppercase;
  opacity: .2;
  font-size: 70px;
}

html {
  position: fixed;
  overflow: hidden;
  font-family: "Orbitron", sans-serif;
}

li {
  list-style-type: none;
}

.slider-box {
  float: right;
  margin: 10px 20px;
  position: relative;
  z-index: 1000;
}

#range {
  margin-top: 5px;
  color: #eee;
  font-size: 10pt;
  margin-left: 10px;
  position: relative;
}

#specks {
  position: absolute;
  z-index: 999;
  height: 100vh;
  width: 100vw;
}

.speck {
  opacity: 0;
  height: 4px;
  width: 4px;
  background: url("http://i1272.photobucket.com/albums/y391/matthew_williams10/speck_zpsu9xym9zl.png");
  background-size: cover;
  position: absolute;
  border-radius: 10px;
  z-index: 99;
}

.img {
  position: absolute;
  height: 100000px;
  width: 100000px;
  top: -8500px;
  left: -8000px;
  animation-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
  animation-name: float;
  animation-iteration-count: infinite;
}

#img-1 {
  opacity: 0.25;
  background: url("https://image.ibb.co/jcTvpv/dust1.jpg");
  background-repeat: repeat;
  animation-duration: 600s;
}

#img-2 {
  opacity: 0.2;
  background: url("https://image.ibb.co/goU1Uv/dust2.jpg");
  background-repeat: repeat;
  animation-duration: 450s;
}

#img-3 {
  opacity: 0.15;
  background: url("https://image.ibb.co/cymRwa/dust3.jpg");
  background-repeat: repeat;
  animation-duration: 350s;
}

/* ANIMATIONS */

@keyframes twinkle {
  0% {
    transform: translate(0px, 0px);
  }

  30% {
    opacity: 0.2;
  }

  50% {
    opacity: 0.75;
  }

  70% {
    opacity: 0;
  }

  100% {
    transform: translate(175px, 100px);
  }
}

@keyframes float {
  0% {
    transform: translate(0px, 0px);
  }

  100% {
    transform: translate(8000px, 4000px);
  }
}

/* Loudout Randomizer */
.loudout-title {
  text-align: center;
}

.loudout-subtitle {
  text-align: center;
}

.main-panel-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 0px;
  width: 100%;
}

.options-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: -250px;
}

.options-container button {
  margin: 1%;
  width: 200px;
}

.loudout-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 3%;
  padding-right: 0%;
}

.weapon-slot-container {
  display: flex;
}

.weapon-slot {
  background-color: rgb(128, 128, 128, 0.1);
  box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.5), -4px -4px 16px rgba(255, 255, 255, 0.05);
  border-style: solid;
  border-color: rgb(128, 128, 128, 0.1);
  border-width: 3px;
  border-radius: 12px;
  width: 500px;
  height: 100px;
  margin-bottom: 3%;
  margin-top: -3%;
  margin-right: 2%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
}

.ammo-slot {
  background-color: rgb(128, 128, 128, 0.1);
  box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.5), -4px -4px 16px rgba(255, 255, 255, 0.05);
  border-style: solid;
  border-color: rgb(128, 128, 128, 0.1);
  border-width: 3px;
  border-radius: 12px;
  width: 50px;
  height: 100px;
  margin-left: 1%;
  margin-top: -3%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: 30%;
  background-size: cover;
}

.tool-slot-container {
  display: flex;
  margin-bottom: 3%;
}

.tool-slot {
  background-color: rgb(128, 128, 128, 0.1);
  box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.5), -4px -4px 16px rgba(255, 255, 255, 0.05);
  border-style: solid;
  border-color: rgb(128, 128, 128, 0.1);
  border-width: 3px;
  border-radius: 12px;
  width: 100px;
  height: 125px;
  margin-right: 4.5%;
  margin-top: -3%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
}

/* Loader */
#loader {
  /* margin: calc(4px - 9em) -9em; */
  border: solid 0 #fff;
  border-width: 2px 0px 0;
  padding: calc(1.625em - 1px) 1.625em;
  border-radius: 50%;
  box-shadow: inset 0 2px 5px #fff;
  filter: blur(0.5px) drop-shadow(0 0 3px red) drop-shadow(0 0 5px red) drop-shadow(0 0 7px red);
  animation: a 1s linear infinite;
}

@keyframes a {
  to {
    transform: rotate(1turn);
  }
}

/* Enter Button */
.wrapper {
  margin: 0 auto;
  text-align: center;
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9000;
}

.btn_fab {
  color: red;
  text-transform: uppercase;
  font-family: 'Lato', sans-serif;
  letter-spacing: 5px;
  font-size: 12px;
  font-weight: bold;
  padding: 15px 25px;
  position: relative;
  cursor: pointer;
  min-width: 300px;
  z-index: 9000;
  /* border: 3px solid red;
  border-radius: 5px; */
  background-color: rgb(128, 128, 128, 0.025);
  /* border-color: rgb(128, 128, 128, 0.1); */
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.5), 0px 0px 30px rgba(255, 255, 255, 0.05);
}

.btn_fab:hover {
  animation-name: change-background;
  animation-duration: .3s;
  -webkit-animation-delay: .3s;
  /* Chrome, Safari, Opera */
  animation-delay: .3s;
  animation-fill-mode: forwards;
}

@keyframes change-background {
  0% {
    background: transparent;
  }

  100% {
    background: red;
    color: #000;
  }
}

.btn_fab:before {
  position: absolute;
  content: "";
  width: 10px;
  height: 3px;
  left: 0;
  top: 22px;
  background-color: red;
  animation-name: right-to-left;
  animation-duration: .3s;
}

.btn_fab:after {
  position: absolute;
  content: "";
  width: 10px;
  height: 3px;
  right: 0;
  top: 22px;
  background-color: red;
  animation-name: right-to-left;
  animation-duration: .3s;
}

@keyframes right-to-left {
  0% {
    width: 100%;
  }

  50% {
    width: 100%;
    right: 0;
  }

  100% {
    width: 10px;
    left: 0;
    right: auto;
  }
}

.btn_fab:hover:before {
  animation-name: left-to-right;
  animation-duration: .3s;
  animation-fill-mode: forwards;
}

@keyframes left-to-right {
  0% {
    width: 10px;
  }

  50% {
    width: 100%;
    left: 0;
  }

  100% {
    width: 10px;
    right: 0;
    left: auto;
  }
}

/* Scroll Indicator */
.scroll-indicator-top {
  z-index: 1000;
  width: 100%;

}

.scroll-indicator-top-about {
  position: absolute;
  top: 170px;
  left: 0px;
  z-index: 1000;
  width: 100%;
}

.top {
  width: 30px;
  height: 30px;
  margin: 0px auto;
  border-left: 2px solid red;
  border-top: 2px solid red;
  border-top-left-radius: 2px;
  transform: rotate(45deg);
  animation-duration: 2s;
  animation-name: fade;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  cursor: pointer;
}

.scroll-indicator-bottom {
  z-index: 1000;
  width: 100%;
}

.bottom {
  width: 30px;
  height: 30px;
  margin: 0px auto;
  border-right: 2px solid red;
  border-bottom: 2px solid red;
  border-bottom-right-radius: 2px;
  transform: rotate(45deg);
  animation-duration: 2s;
  animation-name: fade;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  cursor: pointer;
}

@keyframes fade {
  0% {
    transform: rotate(45deg);
    opacity: 1;
  }

  75% {
    transform: rotate(45deg) translateY(-6px) translateX(-6px);
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

/* Loading Circle */
.right-container {
  min-width: 500px;
  height: 860px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: rgb(17, 141, 250) */
}

@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300&display=swap');

.loading-box {
  min-width: 300px;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #F0F8FF;
  border-radius: 12px;
  /* box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.5), -4px -4px 16px rgba(255, 255, 255, 0.05); */
}

.loading-title {
  font-size: 1.5em;
  transform: translateY(-12px);
  text-transform: uppercase;
  letter-spacing: 4px;
  font-weight: 300;
}

.loading-circle {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 280px;
  height: 280px;
  background: conic-gradient(from 0deg at 50% 50%, rgba(111, 123, 247, 1) 0%, rgba(155, 248, 244, 1) 0%, #101012 0%);
  border-radius: 200px;
  /* box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.75), -4px -4px 16px rgba(255, 255, 255, 0.1); */
  box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.75), -4px -4px 16px red;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading-count {
  content: ' ';
  display: flex;
  justify-content: center;
  align-items: center;
  top: 3px;
  left: 3px;
  min-width: 274px;
  height: 274px;
  border-radius: 200px;
  background: #1D1E22;
  box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.75) inset, -4px -4px 16px rgba(255, 255, 255, 0.1) inset;
  animation: spin 1s infinite linear reverse;
  cursor: pointer;
  color: white;
  text-transform: uppercase;
  font-family: 'Lato', sans-serif;
  letter-spacing: 5px;
  font-size: 12px;
  font-weight: bold;
}

.loading-count-two {
  content: ' ';
  display: flex;
  justify-content: center;
  align-items: center;
  top: 3px;
  left: 3px;
  min-width: 274px;
  height: 274px;
  border-radius: 200px;
  background: #1D1E22;
  box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.75) inset, -4px -4px 16px rgba(255, 255, 255, 0.1) inset;
  animation: spin 1s infinite linear reverse;
  cursor: pointer;
  color: white;
  text-transform: uppercase;
  font-family: 'Lato', sans-serif;
  letter-spacing: 5px;
  font-size: 12px;
  font-weight: bold;
}

.loading-count:hover {
  color: red;
}

.loading-count-two:hover {
  color: red;
  animation: spin 100s linear infinite;
}

.repeat {
  max-height: 100px;
  max-width: 100px;
  transform: scaleX(-1);
  filter: contrast(0%);
}

.repeat:hover {
  animation: a 1000s linear infinite;
}

.loading-count #loadingNumber {
  font-size: 2.5em;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Text Output */
.info-box {
  width: 500px;
  height: 650px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgb(128, 128, 128, 0.1);
  border-color: rgb(128, 128, 128, 0.1);
  box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.5), -4px -4px 16px rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  border-style: solid;
  border-width: 5px;
  border-radius: 10px;
}

@import url(https://fonts.googleapis.com/css?family=Khula:700);

.hidden {
  opacity: 0;
}

.console-container {
  width: 90%;
  text-transform: uppercase;
  font-family: 'Lato', sans-serif;
  letter-spacing: 5px;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  display: block;
  color: red;
  margin: auto;
}

.console-underscore {
  display: inline-block;
  position: relative;
  top: -0.14em;
  left: 10px;
}

/* Left Container */
.left-container {
  min-width: 500px;
  width: 100%;
  height: 900px;
  background-color: rgb(128, 128, 128, 0.1);
  box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.5), -4px -4px 16px rgba(255, 255, 255, 0.05);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 12px;
  border-style: solid;
  border-color: rgb(128, 128, 128, 0.1);
  overflow: hidden;
  border-width: 5px;
  border-radius: 10px;
  padding: 0.5%;
}

.options-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 100%;
}

.optionLarge {
  text-transform: uppercase;
  font-family: 'Lato', sans-serif;
  letter-spacing: 3px;
  font-size: 12px;
  font-weight: bold;
  color: white;
}

.options-box2 {
  display: flex;
  justify-content: center;
  min-width: 100%;
  height: 100%;
}

.form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
}

/* TEST */
.test {
  color: #fff;
}

.checkbox {
  height: 1.5em;
  width: 1.5em;
  cursor: pointer;
  position: relative;
  -webkit-transition: .10s;
  border-radius: 4em;
  accent-color: red;
}

.exclusion {
  color: red;
  margin-top: 10%;
}

.dropdown {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: rgb(128, 128, 128, 0.1);
  box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.5), -4px -4px 16px rgba(255, 255, 255, 0.05);
  width: 100%;
  height: 50px;
  margin-left: 1%;
  cursor: pointer;
}

.clear-button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: rgb(255, 0, 0, 0.3);
  box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.5), -4px -4px 16px rgba(255, 255, 255, 0.05);
  width: 50%;
  height: 30px;
  cursor: pointer;
  border-radius: 6px;
}

.clear-button h5 {
  font-size: 10px;
  font-family: 'Lato', sans-serif;
  letter-spacing: 2px;
  font-weight: bold;
}

.clear-button:hover {
  background-color: red;
}

.dropdown-content {
  display: none;
  position: absolute;
  left: 100%;
  top: 0%;
  background-color: #f9f9f9;
  width: 300px;
  max-height: 300px;
  box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.5), -4px -4px 16px rgba(255, 255, 255, 0.05);
  padding: 12px 16px;
  z-index: 1;
  overflow: scroll;
}

.dropdown-content p {
  color: black;
  font-size: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  text-align: left;
  margin-left: 1%;
}

.dropdown:hover {
  background-color: rgb(128, 128, 128, 0.5);
}

.right-col {
  /* position: absolute;
  top: 37.5%;
  left: 52%; */
  position: relative;
  width: 100%;
  height: 450px;
  background-color: rgb(128, 128, 128, 0.1);
  box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.5), -4px -4px 16px rgba(255, 255, 255, 0.05);
  display: flex;
  flex-direction: column;
  border-style: solid;
  border-color: rgb(128, 128, 128, 0.1);
  padding: 1%;
  border-width: 5px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.info-page {
  position: absolute;
}

.drop-form {
  display: flex;
  align-items: center;
  width: 90%;
}

.example::-webkit-scrollbar {
  display: none;
}

.example {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.left-col {
  display: flex;
  flex-direction: column;
  margin-right: 1%;
  width: 100%;
}

.left-col h5 {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 5px;
  color: WhiteSmoke;
}

.right-col p {
  color: white;
  font-family: 'Lato', sans-serif;
  letter-spacing: 2px;
  font-size: 12px;
  font-weight: bold;
  min-width: 100%;
  margin-left: 1%;
  display: flex;
  align-items: center;
}

.infoHelp {
  background-image: url('https://res.cloudinary.com/dqcmy8k1n/image/upload/v1662070088/Tarot%20Cards/question-mark-circle-icon-1873794492_t70rch.png');
  background-size: cover;
  background-position: center;
  height: 20px;
  width: 20px;
  position: absolute;
  filter: brightness(200%);
  left: 2%;
}

.infoHelpDropdown {
  display: none;
  position: absolute;
  top: 0%;
  left: 110%;
  background-color: rgba(10, 10, 10, 1);
  ;
  width: max-content;
  max-width: 300px;
  box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.5), -4px -4px 16px rgba(255, 255, 255, 0.05);
  padding: 12px 16px;
  z-index: 1;
  border-radius: 12px;
}

.infoHelpDropdown p {
  color: white;
  font-family: 'Lato', sans-serif;
  letter-spacing: 1px;
  font-size: 14px;
}

.infoHelp:hover .infoHelpDropdown {
  display: block;
}

/* ABOUT PAGE */
.about-title {
  text-align: center;
  margin-top: 200px;
}

.about-p {
  color: white;
  text-align: center;
  font-family: 'Lato', sans-serif;
  letter-spacing: 2px;
  font-size: 20px;
  font-weight: bold;
  width: 40%;
}

.about-p span {
  color: red;
}

.about2 {
  color: purple;
}

.divider {
  color: #fff;
  opacity: 0%;
}

.disclaimer {
  width: 70%;
  height: 100%;
  max-height: 400px;
  background-color: rgb(128, 128, 128, 0.1);
  box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.5), -4px -4px 16px rgba(255, 255, 255, 0.05);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 12px;
  border-style: solid;
  border-color: rgb(128, 128, 128, 0.1);
  border-width: 5px;
  border-radius: 10px;
  padding: 0.5%;
  margin-top: 2%;
}

.disclaimer h4 {
  color: red;
}

.disclaimer p {
  text-align: center;
  color: white;
  font-size: 18px;
}

.disclaimer a {
  text-decoration: none;
  color: white;
}

.disclaimer a:hover {
  color: red;
}

/* RANGE SLIDER */
.hunter-rank {
  color: white;
}

#range-slider {
  width: 80%;
  accent-color: red;
}

.slider-diamond {
  position: absolute;
  left: 90%;
  width: 50px;
  height: 50px;
  margin: 0px auto;
  border: 4px solid red;
  transform: rotate(45deg);
}

.slider-output {
  position: absolute;
  left: 91%;
  width: 40px;
  color: red;
  font-weight: bolder;
  font-size: x-large;
  text-align: center;
}

.slider-form {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
}



/* Window Resize - Medium */
@media screen and (max-width: 1700px) {
  .main-panel-container {}

  /* LOUDOUT CONTAINER */
  .Deck h3 {
    font-size: 20px;
  }

  .Deck h4 {
    font-size: 12px;
  }

  .loudout-title {
    text-align: center;
    margin-top: 3%;
  }

  .loudout-subtitle {
    text-align: center;
  }

  .main-panel-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-top: 0px;
    width: 100%;
  }

  .options-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: -250px;
  }

  .options-container button {
    margin: 1%;
    width: 200px;
  }

  .loudout-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 3%;
    padding-right: 0%;
  }

  .weapon-slot-container {
    display: flex;
  }

  .weapon-slot {
    background-color: rgb(128, 128, 128, 0.1);
    box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.5), -4px -4px 16px rgba(255, 255, 255, 0.05);
    border-style: solid;
    border-color: rgb(128, 128, 128, 0.1);
    border-width: 3px;
    border-radius: 12px;
    width: 375px;
    height: 75px;
    margin-bottom: 3%;
    margin-top: -3%;
    margin-right: 2%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position: center;
  }

  .ammo-slot {
    background-color: rgb(128, 128, 128, 0.1);
    box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.5), -4px -4px 16px rgba(255, 255, 255, 0.05);
    border-style: solid;
    border-color: rgb(128, 128, 128, 0.1);
    border-width: 3px;
    border-radius: 12px;
    width: 37.5px;
    height: 75px;
    margin-left: 1%;
    margin-top: -3%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: 30%;
    background-size: cover;
  }

  .tool-slot-container {
    display: flex;
    margin-bottom: 3%;
  }

  .tool-slot {
    background-color: rgb(128, 128, 128, 0.1);
    box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.5), -4px -4px 16px rgba(255, 255, 255, 0.05);
    border-style: solid;
    border-color: rgb(128, 128, 128, 0.1);
    border-width: 3px;
    border-radius: 12px;
    width: 60px;
    height: 75px;
    margin-right: 4.5%;
    margin-top: -3%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
  }


  /* LEFT CONTAINER */
  .left-container {
    min-width: 300px;
    width: 70%;
    height: 550px;
  }

  .options-box {
    min-width: 100%;
  }

  .optionLarge {
    font-size: 9px;
    letter-spacing: 2px;
    margin-left: 8%;
  }

  .form {
    width: 90%;
  }

  .checkbox {
    height: 1em;
    width: 1em;
  }

  .infoHelp {
    height: 15px;
    width: 15px;
    left: 3%;
  }

  .infoHelpDropdown {
    top: 0%;
    left: 110%;
    width: max-content;
    max-width: 240px;
    padding: 12px 16px;
  }

  .infoHelpDropdown p {
    color: white;
    font-family: 'Lato', sans-serif;
    letter-spacing: 1px;
    font-size: 12px;
  }

  .options-box2 {
    display: flex;
    min-width: 90%;
    height: 100%;
  }

  #bloodline {
    font-size: 10px;
  }

  .left-col {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin-right: 2%;
  }

  .dropdown {
    height: 28px;

  }

  .dropdown h5 {
    font-size: 50%;
    letter-spacing: 2px;
  }

  .right-col {
    width: 100%;
    height: 252px;

  }

  .info-page {
    position: absolute;
  }

  .drop-form {
    display: flex;
    align-items: center;
    width: 80%;
  }

  .drop-form p {
    font-size: 40%;
    letter-spacing: 1px;
  }

  .loudout-container {
    margin-top: 3%;
    margin-bottom: 3%;
  }

  .clear-button {
    width: 50%;
    height: 20px;
  }

  .clear-button h5 {
    font-size: 10px;
    font-family: 'Lato', sans-serif;
    letter-spacing: 2px;
    font-weight: bold;
  }

  .clear-button:hover {
    background-color: red;
  }

  .slider-diamond {
    width: 35px;
    height: 35px;
  }

  .slider-output {
    left: 89%;
    font-size: 12px;
  }

  .exclusion {
    color: red;
    margin-top: 7%;
  }

  .slider .navigations {
    min-width: 3vw;
    height: 100%;
    top: 45vh;
    left: 2vw;
    z-index: 1001;
  }

  .slider .navigations .dots li {
    margin: 50%;
  }

  /* RIGHT CONTAINER */
  .right-container {
    min-width: 250px;
    height: 800px;
    margin-top: -5%;
  }

  .loading-title {
    font-size: 1.5em;
    transform: translateY(-12px);
    text-transform: uppercase;
    letter-spacing: 4px;
    font-weight: 300;
  }

  .loading-circle {
    width: 203.636363636px;
    height: 203.636363636px;
  }

  .loading-count {
    min-width: 200px;
    height: 200px;
  }

  .loading-count-two {
    min-width: 200px;
    height: 200px;
  }

  .loading-count #loadingNumber {
    font-size: 1.5em;
  }

  .info-box {
    width: 250px;
    height: 250px;
    margin-top: -60px;
  }

  .info-box span {
    font-size: 8px;
    letter-spacing: 1px;
  }

  .info-box div {
    font-size: 8px;
    letter-spacing: 1px;
  }

  .scroll-indicator-bottom {
    position: absolute;
    top: 700px;
    left: 0px;
    z-index: 1000;
    width: 100%;
  }

}

/* Window Resize - Small */
@media screen and (max-width: 700px) {

  .main-panel-container {
    flex-direction: column-reverse;
    align-items: center;
  }

  .scroll-indicator-bottom {
    position: absolute;
    top: 2900px;
    left: 0px;
    z-index: 1000;
    width: 100%;
  }

  .slider-diamond {
    width: 30px;
    height: 30px;
  }

  .slider-output {
    left: 88%;
    font-size: 12px;
  }

  .slider .navigations {
    min-width: 3vw;
    height: 100%;
    top: 45vh;
    left: 2vw;
    z-index: 1001;
  }

  .slider .navigations .dots li {
    margin: 50%;
  }

  /* LOUDOUT CONTAINER */
  #loader {
    /* margin: calc(4px - 9em) -9em; */
    border: solid 0 #fff;
    border-width: 2px 0px 0;
    padding: calc(1.625em - 1px) 1.625em;
    border-radius: 50%;
    box-shadow: inset 0 2px 5px #fff;
    filter: blur(0.5px) drop-shadow(0 0 3px red) drop-shadow(0 0 1px red) drop-shadow(0 0 7px red);
    animation: a 1s linear infinite;
  }

  .divider {
    opacity: 100%;
    width: 70%;
    margin-top: 10%;
  }

  .Deck h3 {
    font-size: 26px;
    width: 80%;
  }

  .Deck h3 h4 {
    font-size: 16px;
  }

  .loudout-title {
    display: flex;
    flex-direction: column;
  }

  .loudout-container {
    width: max-content;
    margin-top: 10%;
  }

  .loudout-outer-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .loudout-container h4 {
    font-size: 14px;
    width: 100%;
  }

  .weapon-slot {
    width: 200px;
    height: 50px;
    background-size: contain;
    background-position: center;
  }

  .ammo-slot {
    width: 25px;
    height: 50px;
  }

  .tool-slot {
    width: 50px;
    height: 62.5px;
  }

  /* LEFT CONTAINER */

  .clear-button {
    margin-top: -1px;
    margin-bottom: 7px;
  }

  .left-container {
    min-width: 250px;
    width: 70%;
    height: 100%;
    padding: 0%;
    margin-top: 10%;
  }

  .options-box {
    min-width: 100%;
  }

  .optionLarge {
    font-size: 9px;
    letter-spacing: 2px;
    margin-left: 8%;
  }

  .form {
    width: 90%;
  }

  .checkbox {
    height: 1em;
    width: 1em;
  }

  .infoHelp {
    height: 15px;
    width: 15px;
    left: 19%;
  }

  .infoHelpDropdown {
    top: 0%;
    left: 110%;
    width: max-content;
    max-width: 240px;
    padding: 12px 16px;
  }

  .infoHelpDropdown p {
    color: white;
    font-family: 'Lato', sans-serif;
    letter-spacing: 1px;
    font-size: 12px;
  }

  .options-box2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 90%;
    height: 100%;
  }

  .left-col {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-right: 2%;
  }

  .right-col {
    width: 100%;
    height: 495px;
    margin-top: -150px;
    margin-bottom: 10px;
  }

  .info-page {
    position: absolute;
  }

  .drop-form {
    display: flex;
    align-items: center;
    width: 90%;
  }

  .drop-form p {
    font-size: 60%;
    letter-spacing: 1px;
  }

  /* RIGHT CONTAINER */
  .right-container {
    min-width: 250px;
    height: 800px;
    margin-top: -15%;
  }

  .loading-title {
    font-size: 1.5em;
    transform: translateY(-12px);
    text-transform: uppercase;
    letter-spacing: 4px;
    font-weight: 300;
  }

  .loading-circle {
    width: 203.636363636px;
    height: 203.636363636px;
  }

  .loading-count {
    min-width: 200px;
    height: 200px;
  }

  .loading-count-two {
    min-width: 200px;
    height: 200px;
  }

  .loading-count #loadingNumber {
    font-size: 1.5em;
  }

  .info-box {
    width: 250px;
    height: 650px;
  }

  /* ABOUT PAGE */

  .about-p {
    letter-spacing: 2px;
    font-size: 12px;
    width: 70%;
  }

}